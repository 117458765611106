import { Directive } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { ElementInterface, SelectInterface, OptionInterface, } from "../../interfaces";
import { UUID } from 'angular2-uuid';

/**
 * SelectControls class.
 *
 * @export
 * @class SelectControls
 * @extends {BaseControls<ElementInterface>}
 */
@Directive()
export class SelectControls extends BaseControls<ElementInterface> {
    /** @inheritdoc */

    protected register(component: ElementInterface): void {

        const element = component.element as SelectInterface;
        if (!element.field_name) {
            throw new Error("Field name is required.");
        }

        // Add the default "Select One" option
        const selectOneOption = {
            id: UUID.UUID(),
            text: "Select an option",
            is_selected: false,
            disabled: true,
            value: "",
        };
        element.options.unshift(selectOneOption);

        this.documentForm.addControl(
            element.field_name,
            new FormControl("", element.is_required ? Validators.required : null)
        );


        this.documentForm.get(element.field_name)?.valueChanges.subscribe(value => {
            this.selectOption(element.options, value);
        });
    }

    /**
   * Changes the "selected" state in option.
   *
   * @private
   * @param {OptionInterface[]} options Options.
   * @param {string} value Value to be selected.
   * @memberof SelectControls
   */
    private selectOption(options: OptionInterface[], value: string): void {
        options.forEach(o => {
            o.is_selected = o.value === value;
        });
    }
}
