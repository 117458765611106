<div *ngIf="documentForm" [formGroup]="documentForm" [ngStyle]="{
    'padding-top': component.componentSettings?.padding_top + 'px',
    'padding-right': component.componentSettings?.padding_right + 'px',
    'padding-bottom': component.componentSettings?.padding_bottom + 'px',
    'padding-left': component.componentSettings?.padding_left + 'px',
    'background-color': component.componentSettings?.background_color,
    'border-radius': component.componentSettings?.rounded_corners + 'px',
    height:
      component.componentSettings?.height ?? 0 > 0
        ? component.componentSettings?.height + 'px'
        : 'auto'
  }" class="form-check ms-0">
  <input type="checkbox" class="form-check-input ms-0 me-2" [id]="component.id" [formControlName]="element.field_name" />
  <label [for]="component.id">
    {{ element.label }}
    <span *ngIf="element.is_required" class="text-danger">*</span>
  </label>
  <app-error *ngIf="element.is_required" [field]="name" requiredMessage="Checkbox">
  </app-error>
</div>