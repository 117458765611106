import { Component, Input, OnInit } from "@angular/core";
import { ElementInterface, SelectInterface } from "../../interfaces";
import { SelectControls } from "./dropdown.controls";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent extends SelectControls implements OnInit {
  @Input() component!: ElementInterface;
  @Input() formDesign: any;

  ngOnInit() {
    this.register(this.component);
  }

  get element(): SelectInterface {
    return this.component.element as SelectInterface;
  }

  get name() {
    return this.documentForm.get(this.element.field_name);
  }
}
