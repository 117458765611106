import { FormControl, Validators } from "@angular/forms";
import { BaseControls } from "../../controls/base.controls";
import { AdvancedElementInterface } from "../../interfaces/advanced-element.interface";

export enum Keys {
  FullName = "emergency@fullName",
  Email = "emergency@email",
  Phone = "emergency@phone",
  Job = "emergency@job",
  Relationship = "emergency@relationship",
}

/**
 * Class for managing emergency contact information form controls.
 *
 * @export
 * @class EmergencyContactControls
 * @extends {BaseControls<AdvancedElementInterface>}
 */
export class EmergencyContactControls extends BaseControls<AdvancedElementInterface> {
  /** @inheritdoc */
  protected register(component: AdvancedElementInterface): void {
    const fields = (component.fields ??= {
      FullName: "",
      Email: "",
      Phone: "",
      Job: "",
      Relationship: "",
    });

    this.documentForm.addControl(
      Keys.FullName,
      new FormControl(fields["FullName"], Validators.required)
    );
    this.documentForm
      ?.get(Keys.FullName)
      ?.valueChanges.subscribe(value => (fields["FullName"] = value));

    this.documentForm.addControl(
      Keys.Email,
      new FormControl(fields["Email"], Validators.email)
    );
    this.documentForm
      ?.get(Keys.Email)
      ?.valueChanges.subscribe(value => (fields["Email"] = value));

    this.documentForm.addControl(
      Keys.Phone,
      new FormControl(fields["Phone"], [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
        Validators.maxLength(10),
      ])
    );
    this.documentForm
      ?.get(Keys.Phone)
      ?.valueChanges.subscribe(value => (fields["Phone"] = value));

    this.documentForm.addControl(Keys.Job, new FormControl(fields["Job"]));
    this.documentForm
      ?.get(Keys.Job)
      ?.valueChanges.subscribe(value => (fields["Job"] = value));

    this.documentForm.addControl(
      Keys.Relationship,
      new FormControl(fields["Relationship"], [Validators.required])
    );
    this.documentForm
      ?.get(Keys.Relationship)
      ?.valueChanges.subscribe(value => (fields["Relationship"] = value));
  }
}
