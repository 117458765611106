import { Component, Input, OnInit } from "@angular/core";
import { MultiSelectControls } from "../../controls/multi-select.controls";
import { MultipleSelectInterface } from "../../interfaces/multiple-select.interface";

@Component({
  selector: "app-radios",
  templateUrl: "./radios.component.html",
  styleUrls: ["./radios.component.scss"],
})
export class RadiosComponent extends MultiSelectControls implements OnInit {
  @Input() component: any;
  @Input() formDesign: any;

  ngOnInit() {
    this.register(this.component);
  }

  get element(): MultipleSelectInterface {
    return this.component.element as MultipleSelectInterface;
  }

}
